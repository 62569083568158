
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<!-- <div v-if="user.user_role!='viewer'"> -->
			<!-- <button>
				<v-btn @click="createUserInFirebase">Create User In Firebase</v-btn>
			</button> -->
            <p>In order to stay in current user account after creating a new user write your password first</p>
            <v-text-field v-model="old_password" type="password" label="Your Password" dense class="mx-1" filled outlined >
            </v-text-field>
            <h1 class="mt-3 mb-5">{{$store.getters.language.data.users.add_btn}}</h1>
            <div v-if="old_password != ''">
			<form @submit.prevent="createUserInFirebase" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="users.user_name" type="text" :label="$store.getters.language.data.users.user_name" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="password" type="text" label="password" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="users.firebase_uid" type="textarea" :label="$store.getters.language.data.users.firebase_uid" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex> -->

					<v-flex xs12 lg4 xl4 md3 sm4>
						<v-text-field v-model="users.user_email" type="textarea" :label="$store.getters.language.data.users.user_email" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md3 sm4>
						<v-autocomplete class="mx-1" clearable :items="employees" v-model="users.employee_id" dense filled outlined item-text="employee_name" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name">
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.users.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
            </div>

			<!-- </div> -->
			<v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-text-field v-model="search" append-icon="mdi-magnify" outlined></v-text-field>
                </v-flex>

				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers"  v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="user_id">
								<template v-slot:[`item.user_id`]="{ item }">
									<div>
										<v-btn icon :to="'/users-list/'+item.user_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectUsers(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteUsersList">{{$store.getters.language.data.users.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.users.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.users.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteUsers(selected_users.user_id)">
						{{$store.getters.language.data.users.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import router from '../../router/index.js';
	import requests from './../../requests/users.request.js'
	import employeesrequests from './../../requests/employees.request.js'
	// import firebase to create user in firebase
	import { getAuth, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword } from "firebase/auth";
import { read } from 'xlsx';

	export default {
		data() {
			return {
				users: {},
				search: '',
				loading: true,
				loading_btn: false,
				password: '123456',
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_users: {},
				delete_dialog: false,
				originalUser: null,
                old_password: '',
				headers: [


					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					},
					{
						text: this.$store.getters.language.data.users.firebase_uid,
						align: 'start',
						sortable: true,
						value: 'firebase_uid',
					},
					{
						text: this.$store.getters.language.data.users.user_email,
						align: 'start',
						sortable: true,
						value: 'user_email',
					},
					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
                    {
                        text: 'Role',
                        align: 'start',
                        sortable: true,
                        value: 'user_role',

                    },
                    {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'user_id',
					}
				],
			}
		},
		computed: {
			// employees() {
			// 	return this.$store.getters.employees_list
			// },
		},
		mounted() {
            setTimeout(() => {
                this.readUsers();
                this.readEmployees();
            }, 1000);
	
		},
		methods: {
			async createUserInFirebase() {
				const auth = getAuth();
				try {
					// Save the current user
					if (auth.currentUser) {
						this.originalUser = auth.currentUser;
					}

					// Create a new user
					const userCredential = await createUserWithEmailAndPassword(auth, this.users.user_email, this.password);

					// Handle any necessary operations with the new user here
					this.users.firebase_uid = userCredential.user.uid
					// this.users.employee_id = 60
					requests.createUsers(this.users).then(r => {
						if (r.status == 200) {
							this.users = {}

							this.snackbar = {
								value: true,
								text: 'Users Added',
								color: 'success'
							}
						} else {
							this.snackbar = {
								value: true,
								text: 'Fail to add Users',
								color: 'error'
							}
						}
					})
					// Notify about successful user creation
					this.snackbar = {
						value: true,
						text: 'User created successfully.',
						color: 'success'
					};

					// Optionally, sign out the new user if you don't want them logged in
					await signOut(auth);

					// Optionally, switch back to the original user if needed
					if (this.originalUser) {
						await signInWithEmailAndPassword(auth, this.originalUser.email, this.old_password);
						router.push('/users-list');
					}

					// Clear the saved original user
					this.originalUser = null;

				} catch (error) {
					console.error('Error creating user:', error.message);
					this.snackbar = {
						value: true,
						text: `Error creating user: ${error.message}`,
						color: 'error'
					};
				}

			},
			addUsers() {
				this.loading_btn = true
				requests.createUsers(this.users).then(r => {
					if (r.status == 200) {
						this.users = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Users Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Users',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteUsers(user_id) {
				requests.deleteUsers(user_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.user_id != user_id
						})
						this.snackbar = {
							value: true,
							text: 'Users Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteUsersList() {
				let ids = this.selected_rows.map(m => m.user_id)
				requests.deleteUsersList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.user_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Users Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readUsers() {
				this.loading = true
				requests.getAllUsers().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Users',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Users',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
            readEmployees() {
                employeesrequests.getAllEmployees().then(r => {
                    if (r.status == 200) {
                        this.employees = r.data.rows
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Employees',
                            color: 'error'
                        }
                    }
                })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Employees',
                            color: 'error'
                        }
                    })
            },
			selectUsers(i) {
				this.selected_users = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    